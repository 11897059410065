import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Container,
	Stack,
	Typography
} from '@mui/material'

import { attachUserAndPractice } from 'apis/user'
import { MintButton } from 'components/common/MintButton'
import { ScratchLogo } from 'components/common/ScratchLogo'
import CustomChip from 'components/CustomChip'
import { useCurrentPractice } from 'contexts/PracticeContext'
import { setContext } from 'slices/auth'
import { isLightMode } from 'theme'
import { validateUser } from 'util/unified'

import { PRACTICE_ROLE_HOSPITAL_MANAGER } from '../constants'
import { PRACTICE_ROLE_STAFF } from '../constants'
import { logout } from 'lib/firebase'

const chipSx = {
	justifyContent: 'flex-start',
	px: 1,
	py: 1.5,
	fontSize: '0.9rem',
	height: 'auto'
}

type SelectUserRoleProps = {
	user: User
}

export const SelectUserRole: React.FC<SelectUserRoleProps> = ({ user }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { currentPracticeObject } = useCurrentPractice()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [selectedRole, setSelectedRole] = useState('')
	const practiceName = useMemo(
		() => currentPracticeObject?.clinicName,
		[currentPracticeObject?.clinicName]
	)

	const handleLogout = async () => {
		try {
			await logout()
			navigate('/')
		} catch (err) {
			console.error(err)
			toast.error('Unable to logout')
		}
	}

	const handleUpdateRole = async () => {
		setIsSubmitting(true)

		const toastId = toast.loading('Updating your role...')
		try {
			await attachUserAndPractice(
				user.uid,
				currentPracticeObject.stripeAccountId as string,
				selectedRole
			)

			toast.success('Role updated successfully!', {
				id: toastId
			})

			const newContext = await validateUser(user as any)
			dispatch(setContext(newContext))
			navigate('/')
		} catch (error: any) {
			toast.error(`Failed to update your role: ${error.message}`, {
				id: toastId
			})
		}

		setIsSubmitting(false)
	}

	return (
		<>
			<Helmet>
				<title>Select User Role | Scratchpay Solutions</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh'
				}}
			>
				<Container sx={{ py: '80px', width: 500 }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							mb: 4
						}}
					>
						<RouterLink to="/">
							<ScratchLogo size={100} />
						</RouterLink>
					</Box>

					<Card
						sx={{
							p: 2,
							boxShadow: ({ palette }) =>
								isLightMode(palette) ? '0px 1px 8px 02px #E8E8FF' : '0px 1px 8px 02px #1D1D30'
						}}
					>
						<CardContent>
							<Box>
								<Typography color="textPrimary" gutterBottom variant="h4" sx={{ lineHeight: 1.3 }}>
									What is your position at the practice?
								</Typography>
								<Typography color="textSecondary" variant="body2">
									Select the option that best describes your role at <strong>{practiceName}</strong>
									:
								</Typography>
							</Box>

							<Stack direction="column" spacing={1} py={4}>
								<CustomChip
									label="Staff Member"
									variant="tertiary"
									selected={selectedRole === PRACTICE_ROLE_STAFF}
									onClick={() => setSelectedRole(PRACTICE_ROLE_STAFF)}
									sx={chipSx}
								/>
								<CustomChip
									label="Practice Manager"
									variant="tertiary"
									selected={selectedRole === PRACTICE_ROLE_HOSPITAL_MANAGER}
									onClick={() => setSelectedRole(PRACTICE_ROLE_HOSPITAL_MANAGER)}
									sx={chipSx}
								/>
							</Stack>
						</CardContent>

						<CardActions>
							<Box display="flex" justifyContent="flex-end" width="100%" pt={1} gap={2}>
								<Button
									size="large"
									variant="text"
									onClick={handleLogout}
									disabled={isSubmitting}
									sx={{ width: 130, color: ({ palette }) => palette.text.primary }}
								>
									Logout
								</Button>
								<MintButton
									sx={{
										color: '#0c6651 !important',
										width: 130,
										maxWidth: '100%',
										'&.Mui-disabled': {
											backgroundColor: '#cfdaf0 !important',
											color: '#7981a1 !important'
										}
									}}
									onClick={handleUpdateRole}
									disabled={isSubmitting || !selectedRole}
								>
									Continue
								</MintButton>
							</Box>
						</CardActions>
					</Card>
				</Container>
			</Box>
		</>
	)
}
