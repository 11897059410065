import { Button, ButtonProps } from '@mui/material'

export const MintButton: React.FC<ButtonProps> = ({ children, sx = {}, ...props }) => (
	<Button
		color="primary"
		size="large"
		variant="contained"
		data-cy="send-ar-request-button"
		sx={{
			borderRadius: '8px !important',
			fontWeight: '600',
			px: 2.5,
			py: 1.2,
			...sx
		}}
		{...props}
	>
		{children}
	</Button>
)
