// Chip for the new dashboard design
import { Chip, SxProps } from '@mui/material'
import { MouseEventHandler } from 'react'

type CustomChipProps = {
	selected: boolean
	label: string
	onClick: MouseEventHandler<HTMLDivElement>
	sx: SxProps
	variant?: 'primary' | 'secondary' | 'tertiary'
}

const CustomChip = (props: CustomChipProps) => {
	const { selected, label, onClick, sx, variant = 'primary' } = props
	let styles = {}
	if (variant === 'primary') {
		styles = {
			border: selected ? '1px solid #55CFB4' : '1px solid #EBEBFB',
			color: (theme) =>
				theme.palette.mode === 'dark' && !selected ? theme.palette.text.primary : '#1D1C2F',
			backgroundColor: (theme) =>
				selected ? (theme.palette.text as any).mint : theme.palette.primary,
			fontWeight: selected ? 600 : 0,
			...sx
		}
	}

	if (variant === 'secondary') {
		styles = {
			border: '1px solid #5B70FF',
			color: !selected ? '#5B70FF' : '#fff',
			backgroundColor: selected ? '#5B70FF' : '#fff',
			...sx
		}
	}

	if (variant === 'tertiary') {
		styles = {
			border: 'none',
			color: !selected ? '#1D1C2F' : '#F7F8FF',
			backgroundColor: selected ? '#5B70FF' : '#F7F8FF',
			fontWeight: selected ? 600 : 400,
			...sx
		}
	}

	return (
		<Chip
			sx={{ ...styles }}
			label={label}
			variant="outlined"
			onClick={onClick}
			clickable={!selected}
		/>
	)
}

export default CustomChip
